<template>
  <div class="back-view" @click="onClick">
    <div class="wrapper">
      <!--  <Navbar title="注册" type="3" /> -->
      <div class="bg">
        <P class="title">注册{{ inviteInfo.appName }}</P>
        <div class="flex flex-a-center rec-view">
          <p class="rec-tip">推荐人</p>
          <div class="rec-line"></div>
          <p class="rec-name">{{ inviteInfo.redCode }}</p>
        </div>

        <van-cell-group class="input-area">
          <van-field
            v-model="phone"
            label=""
            placeholder="请输入手机号"
            center
            clearable
            maxlength="11"
          >
          </van-field>
        </van-cell-group>
        <van-cell-group class="input-area">
          <van-field
            v-model="smsCode"
            label=""
            placeholder="请输入验证码"
            center
            clearable
          >
            <template #button>
              <div class="flex">
                <div class="code-line"></div>
                <Smsbutton
                  :phone="phone"
                  :packageName="inviteInfo.packageName"
                />
              </div>
            </template>
          </van-field>
        </van-cell-group>

        <p class="pwd-rule">设置登录密码，8-20位数字与字母或字符组合</p>

        <van-cell-group class="input-area">
          <van-field
            type="password"
            v-model="pwd"
            label=""
            placeholder="请输入密码"
            center
            clearable
            autocomplete="new-password"
          >
          </van-field>
        </van-cell-group>

        <van-cell-group class="input-area">
          <van-field
            type="password"
            v-model="confirmPwd"
            label=""
            placeholder="请再次输入密码"
            center
            clearable
          >
          </van-field>
        </van-cell-group>

        <van-button
          :class="complete ? 'blue' : 'gray'"
          v-debounce="registerClick"
          :disabled="!complete"
          >立即注册</van-button
        >

        <div class="footer flex flex-justify-center">
          <img
            class="icon"
            :src="checkImgArr[~~isCheck]"
            alt=""
            @click="checkProtocol"
          />
          <span class="t-light-gray">我已阅读并同意，</span>
          <a class="t-blue" :href="agreementData.secrecyAgreement"
            >《隐私协议》</a
          >
          <span class="t-light-gray">和</span>
          <a class="t-blue" :href="agreementData.userAgreement">《用户协议》</a>
        </div>
      </div>
      <div class="btn-download">
        <div class="titles">下载{{ inviteInfo.appName }}APP</div>
        <div class="text">
          <span class="point"></span>
          <span class="point"></span>
          <span class="point" style="margin-right: 10px"></span>
          开启财富新思路
          <span class="point ml-10" style="margin-left: 10px"></span>
          <span class="point"></span>
          <span class="point"></span>
        </div>
        <div class="flex-between">
          <van-button
            class="btn right"
            @click="toDownload('android')"
            v-show="downloadObj.androidDownloadUrl"
          />
          <van-button
            class="btn left"
            @click="toDownload('ios')"
            v-show="downloadObj.iosDownloadUrl"
          ></van-button>
        </div>
      </div>
    </div>
    <prompt
      v-model="showPrompt"
      desc="注册成功，立即去下载APP"
      @confirm="onConfirm"
    />

    <!-- <van-overlay :show="showOverlay" @click="showOverlay = false" lock-scroll>
      <div class="wechat-tip"></div>
    </van-overlay> -->
  </div>
</template>

<script>
import prompt from '@/components/prompt'
export default {
  name: "register",
  components: {
    prompt
  },
  data() {
    return {
      isCheck: false,
      showPrompt: false,
      checkImgArr: [
        require("./images/icon_check_default.svg"),
        require("./images/icon_checked.svg"),
      ],

      inviteInfo: {
        packageName: '',// 包名
        redCode: '',
        appName: '',// app名称
        platformId: ''// 平台id
      },
      phone: "",
      smsCode: "",
      pwd: "",
      confirmPwd: "",
      downloadUrl: '',// app下载地址
      agreementData: {
        userAgreement: '',
        secrecyAgreement: ''
      },
      showOverlay: false,
      downloadObj: {
        androidDownloadUrl: '',
        iosDownloadUrl: '',
      }
    };
  },
  created() {
    this.inviteInfo = this.$route.query;
    this.inviteInfo.appName = decodeURIComponent(this.$route.query.appName);
    this.inviteInfo.platformId = this.$route.query.platformId;
    this.getDownloadUrl()
    this.loadAgreement()

    //if (this.isWeChat) { this.showOverlay = true }
  },
  computed: {
    complete() {
      return this.phone && this.smsCode && this.pwd && this.confirmPwd && this.isCheck;
    },
    isIos() {
      const u = navigator.userAgent;
      const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      return isIos
    },
    isWeChat() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true
      }
      return false
    }
  },
  methods: {
    async getDownloadUrl() {
      const res = await this.$api.user.operate_download_url({
        packageName: this.inviteInfo.packageName,
        platformId: this.inviteInfo.platformId,
      });
      this.downloadObj = res.data.data
    },
    onClick() {

    },
    onSecrecy() {
      this.$router.push({ name: 'secrecyAgreement' })
    },
    onUser() {
      this.$router.push({ name: 'userAgreement' })
    },
    async registerClick() {
      if (this.pwd !== this.confirmPwd) {
        this.$toast('密码不一致');
        return;
      }

      const params = {
        code: this.smsCode,
        password: this.$encrypt(this.pwd),
        phone: this.phone,
        recommendCode: this.inviteInfo.redCode
      };
      try {
        const res = await this.$api.user.login_registered(params);
        /* const { data } = await this.$api.user.get_downloadUrl({
          packageName: this.inviteInfo.packageName,
          osType: this.isIos ? 200 : 100 //app平台类型 100=android 200=ios
        }) */
        this.downloadUrl = this.isIos ? this.downloadObj.iosDownloadUrl : this.downloadObj.androidDownloadUrl
        this.showPrompt = true
      } catch (err) {
        console.log('err===>>>', err);
      }
    },
    async loadAgreement() {
      try {
        const res = await this.$api.user.agreement_info({ name: this.inviteInfo.packageName });
        const { data } = res.data;
        this.agreementData.userAgreement = this.setImgPrefix(data.userAgreement)
        this.agreementData.secrecyAgreement = this.setImgPrefix(data.secrecyAgreement)
      } catch (e) {
        console.log('%c 🍿 e: ', 'font-size:20px;background-color: #33A5FF;color:#fff;', e);
      }
    },
    onConfirm() {
      if(!this.downloadUrl) return this.$toast('暂无下载地址')
      var oA = document.createElement("a"); //创建a标签
      oA.href = this.downloadUrl; //添加 href 属性
      oA.click(); //模拟点击
    },
    checkProtocol() {
      this.isCheck = !this.isCheck;
    },
    // 去下载
    toDownload(type) {
      let oA = document.createElement("a"); //创建a标签
      let url = type == 'ios' ? this.downloadObj.iosDownloadUrl : this.downloadObj.androidDownloadUrl
      if (url) {
        oA.href = url //添加 href 属性
        oA.click();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_mixins.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.bg {
  flex: 1;
  padding: 24px 32px;
  background-color: #f3f5f9;

  .title {
    color: #313235;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 6px;
  }

  .rec-view {
    margin-top: 32px;
    padding: 0 16px;
    background-color: #fff;
    border-radius: 6px;
    height: 48px;

    .rec-tip {
      color: #313235;
      font-size: 14px;
      font-weight: 500;
    }

    .rec-line {
      width: 1px;
      height: 22px;
      margin-left: 16px;
      margin-right: 12px;
      background-color: #e2e4ec;
    }

    .rec-name {
      color: #313235;
      font-size: 14px;
    }
  }

  .input-area {
    margin-top: 12px;
    font-size: 14px;
    color: #93949d;
    border-radius: 6px;
    overflow: hidden;
    line-height: 52px;

    .code-line {
      width: 1px;
      height: 22px;
      margin-right: 16px;
      background-color: #e2e4ec;
    }
  }

  .pwd-rule {
    margin-top: 12px;
    color: #93949d;
    font-size: 12px;
    font-weight: 500;
  }

  .van-button {
    margin-top: 40px;
    border-radius: 6px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
  }

  .gray {
    background-color: #e2e4ec;
    color: #93949d;
  }

  .blue {
    background-color: #2e5bfd;
    color: #fff;
  }

  .footer {
    margin-top: 20px;
    font-size: 12px;
    @include safeArea(padding-bottom);
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
.btn-download {
  padding: 16px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: url("./images/bg-footer.png") 0 0 no-repeat;
  background-size: 100%;
  height: 184px;
  .titles {
    text-align: center;
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: #313235;
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 0 16px;
    .point {
      display: inline-block;
      height: 4px;
      width: 4px;
      margin: 0 2px;
      transform: rotateZ(135deg);
      -webkit-transform: rotateZ(135deg);
      background: #cfcfd3;
    }
  }
  .left {
    background: url("./images/ios.png") 0 0 no-repeat;
    background-size: 100%;
  }
  .right {
    background: url("./images/android.png") 0 0 no-repeat;
    background-size: 100%;
  }
  .btn {
    margin-top: 0;
    width: 148px;
    height: 48px;
    border: none;
  }
}

.wechat-tip {
  margin-top: 35px;
  margin-left: 60px;
  margin-right: 4px;
  box-sizing: border-box;
  height: 100vh;
  background: url("./images/wechat-tip.png") 0 0 no-repeat;
  background-size: 100%;
}
</style>